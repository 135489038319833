/* eslint-disable */


export function getHTMLDateInputString(date: Date | string | number): string {

  if (!date) {
    return '';
  }

  if (!(date instanceof Date)) {
    date = new Date(date);
  }

  const datePadding = 2;
  return `${date.getFullYear()}-${((date.getMonth() + 1) + '').padStart(datePadding, '0')}-${(date.getDate() + '').padStart(datePadding, '0')}`;
}

export function getEndOfDay(date: Date | string | number, newInstance?: boolean): Date {

  if (!date) {
    return null;
  }

  if (newInstance || !(date instanceof Date)) {
    date = new Date(date);
  }

  date.setDate(date.getDate() + 1);
  date.setHours(0, 0, 0, 0);
  date.setMilliseconds(date.getMilliseconds() - 1);
  return date;
}

/**
 * Convert a date, used by the HTML input (type="date") element into a datetime string matching the ISO8601 format
 * If "relativeToUser" is true than the created string represents the time from the perspective of the user / client in a internationally standardized way.
 * "relativeToUser" is by default true
 * "dayTime" is by default "same"
 */
export function convertHTMLDateInputStringToISO8601(date: string, relativeToUser: boolean = true, dayTime: 'same' | 'midnight' | 'morning' | 'noon' | 'afternoon' | 'evening' | 'night' = 'same'): string {

  if (!date) {
    return '';
  }

  let dateObject: Date;

  try {

    dateObject = new Date(date);

    if (relativeToUser) {
      const dayOfTheMonth = dateObject.getDate();
      const month = dateObject.getMonth();
      const year = dateObject.getFullYear();

      dateObject = new Date();
      dateObject.setDate(dayOfTheMonth);
      dateObject.setMonth(month);
      dateObject.setFullYear(year);

      switch(dayTime) {
        case 'midnight' : dateObject.setHours(0, 0, 0, 0); break;
        case 'morning' : dateObject.setHours(6, 0, 0, 0); break;
        case 'noon' : dateObject.setHours(12, 0, 0, 0); break;
        case 'afternoon' : dateObject.setHours(14, 0, 0, 0); break;
        case 'evening' : dateObject.setHours(18, 0, 0, 0); break;
        case 'night' : dateObject.setHours(22, 0, 0, 0); break;
      }
    }

    return dateObject.toISOString();

  } catch(e) {
    console.warn(`tried to convert HTMLDateInputString "${date}" to ISO-8601 but failed`, e);
    return '';
  }
}

export class UtilDateHelper {

  /**
   * uses Date.prototype.getTimezoneOffset() which returns the time zone offset in minutes of the current local machine
   * NOT the timezone of the value used to construct the date object
   */
  static getISOStringWithLocalOffset(date: Date): string {

    const tzo = Math.abs(date.getTimezoneOffset());
    const sign = date.getTimezoneOffset() > 0 ? '-' : '+';

    const padFn = (num: number, len = 2): string => {
      return String(num).padStart(len, '0')
    };

    const dateStr = `${date.getFullYear()}-${padFn(date.getMonth() + 1)}-${padFn(date.getDate())}`;
    const timeStr = `T${padFn(date.getHours())}:${padFn(date.getMinutes())}:${padFn(date.getSeconds())}.${padFn(date.getMilliseconds(), 3)}`;
    const offsetStr = `${sign}${padFn(Math.floor(tzo / 60))}:${padFn(tzo % 60)}`;

    return dateStr + timeStr + offsetStr;
  }

  static getTimeZoneOffsetMap(): Map<string, number> {

    const timezones = this.getIANATimeZones();
    const map = new Map<string, number>();

    timezones.forEach(tz => {
      const offset = this.getTimeZoneOffset(tz);
      if (typeof offset === 'number' && !isNaN(offset)) {
        map.set(tz, offset);
      }
    });

    return map;
  }

  static getTimeZoneOffset(ianaTimeZone?: string): number {

    ianaTimeZone ||= Intl.DateTimeFormat().resolvedOptions().timeZone;

    const now = new Date();
    now.setSeconds(0, 0);
    const tzDateString = now.toLocaleString('en-US', {
      timeZone: ianaTimeZone,
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hourCycle: 'h23',
    });
    // matches example "06/04/2000, 13:45:12"
    const match = /([\d]{2})\/([\d]{2})\/([\d]{4})\,\s([\d]{2})\:([\d]{2})/.exec(tzDateString);
    const [_, month, day, year, hour, min] = match.map(Number);
    const tzTime = Date.UTC(year, month - 1, day, hour, min);
    return Math.floor((tzTime - now.getTime()) / (1000 * 60));

  }

  static getIANATimeZones(): string[] {
    // supportedValuesOf is not yet supported by typescript (4.9.x) version -> todo: test it with 5.1.3 and above
    // https://caniuse.com/?search=supportedValuesOf
    return (Intl as unknown as {supportedValuesOf: (tz: string) => string[]}).supportedValuesOf('timeZone');
  }

  static addDays(date: Date, n: number, inSameObject?: boolean): Date {
    return this.addTime(date, 'day', n, inSameObject);
  }

  static addMonths(date: Date, n: number, inSameObject?: boolean): Date {
    return this.addTime(date, 'month', n, inSameObject);
  }

  static addYears(date: Date, n: number, inSameObject?: boolean): Date {
    return this.addTime(date, 'year', n, inSameObject);
  }

  static addTime(date: Date, timeUnit: ('year' | 'month' | 'day'), n: number, inSameObject?: boolean): Date {

    let returnDate: Date;
    let setter: keyof Date = 'setFullYear';
    let getter: keyof Date = 'getFullYear';

    if (timeUnit === 'month') {
      setter = 'setMonth';
      getter = 'getMonth';
    }

    if (timeUnit === 'day') {
      setter = 'setDate';
      getter = 'getDate';
    }

    if (inSameObject) {
      date[setter]( date[getter]() + n);
      returnDate = date;
    } else {
      returnDate = new Date(date);
      returnDate[setter]( date[getter]() + n);
    }

    return returnDate;


  }
}

/**
 * Transforms a string, in which one or more time indications are listed, and returns
 * the sum of milliseconds.
 * @example const extraMs = getMillisecondsOfUnits('2w 1d 2h 34m 59s 600ms');
 * @param time - string of one or more indications about time
 */
export function getMillisecondsOfUnits(time: string): number {

  const [s, m, h, d, w] = [
    1000,
    1000 * 60,
    1000 * 60 * 60,
    1000 * 60 * 60 * 24,
    1000 * 60 * 60 * 24 * 7
  ];
  const units = time.replace(/ {2}/g, ' ').split(' ').filter(u => !!u);
  let ms = 0;
  units.forEach(u => {
    const regex = /^([\d\.]{1,})([ms|msec|s|sec|m|min|h|hr|d|day|w]{1,4})$/;
    const res = regex.exec(u);
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    switch(res?.[2]) {
      case 'ms': case 'msec': ms += parseFloat(res[1]); break;
      case 's': case 'sec': ms += (parseFloat(res[1]) * s); break;
      case 'm': case 'min': ms += (parseFloat(res[1]) * m); break;
      case 'h': case 'hr': ms += (parseFloat(res[1]) * h); break;
      case 'd': case 'day': ms += (parseFloat(res[1]) * d); break;
      case 'w': ms += (parseFloat(res[1]) * w); break;
      default: console.warn('Was not able to transform \'' + u + '\' to milliseconds');
    }
  });

  return ms;
}


export function getDatePickerLocaleData(locale: string) {
  const monthsLong = [...Array(12).keys()].map(key => new Date(0, key).toLocaleString(locale, { month: 'long' }));
  const weekdaysLong = [...Array(7).keys()].map(key => new Date(1970, 0, (key + 4)).toLocaleString(locale, {weekday: 'long'}));
  const monthsShort = [...Array(12).keys()].map(key => new Date(0, key).toLocaleString(locale, { month: 'short' }));
  const weekdaysShort = [...Array(7).keys()].map(key => new Date(1970, 0, (key + 4)).toLocaleString(locale, {weekday: 'short'}));

  // todo Other data
  const e = [...Array(7).keys()].map(key => new Date(1970, 0, (key + 4)).toLocaleString(locale, {}));

  return {
    monthsLong,
    monthsShort,
    weekdaysLong,
    weekdaysShort,

  };
}


export function getFileDateString(date: Date, type: 'datetime' | 'date' | 'time' = 'datetime'): string {

  const padding = 2;

  const arr: string[] = [];

  if (type === 'datetime' || type === 'date') {
    arr.push(`${date.getFullYear()}-${((date.getMonth() + 1) + '').padStart(padding, '0')}-${(date.getDate() + '').padStart(padding, '0')}`);
  }

  if (type === 'datetime' || type === 'time') {
    arr.push(`${(date.getHours() + '').padStart(padding, '0')}-${(date.getMinutes() + '').padStart(padding, '0')}-${(date.getSeconds() + '').padStart(padding, '0')}`);
  }

  return arr.join('-');
}
